<template>
  <div id="mornitor">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-4">
          {{branchs.length}} Plants
          <button type="button" class="button is-small is-info" style="float:right;" @click="new_branch=true">
            <span class="mr-2 is-hidden-mobile">Create Plants</span>
            <i class="fas fa-plus"></i>
          </button>
        </strong>
        <hr>
        <div class="has-text-centered" v-if="!branchs.length">
          <hr>You didn't create any plants!
        </div>
        <FormBranch :branchs="branchs" @edit_branch="edit_branch" v-else></FormBranch>
      </div>
    </div>

    <modal-branch :show="new_branch" :branch="branch" @submit="submit_branch" @close="new_branch=false; branch={};"></modal-branch>

  </div>
</template>

<script>
import FormBranch from '@/components/Form/Branch'
import ModalBranch from '@/components/Modal/Branch'

export default {
  name: 'MemberMornitor',
  components:{ FormBranch, ModalBranch },
  props: {
    userData: Object,
    loaded: Object,
    branchs: Array,
  },
  data(){
    return {
      new_branch: false,
      branch: {},
    }
  },
  created(){
    if(!this.loaded.get_branch){
      this.$emit('get_branch');
    }
  },
  methods:{
    edit_branch(val){
      this.branch = val;
      this.new_branch = true;
    },
    submit_branch(input){
      if(!input.id){
        this.submit_new_branch(input)
      }else{
        this.submit_edit_branch(input)
      }
      this.new_branch = false;
    },
  }
}
</script>
