<template>
  <!-- is-half-desktop -->
  <div class="columns is-multiline">
    <div class="column is-one-third-desktop" v-for="(val,key) in branchs" :key="key">
      <article class="message is-info">
        <div class="message-header py-1">
          <p class="w-100 cursor_pointer" @click="redirect_to_branch(val)">
            <i class="fas fa-building"></i>
            {{val.name}}
          </p>
          <div class="dropdown is-right is-hoverable">
            <div class="dropdown-trigger">
              <button class="button is-xs">
                <i class="fas fa-chevron-down"></i>
              </button>
            </div>
            <div class="dropdown-menu">
              <div class="dropdown-content">
                <a class="dropdown-item" @click="edit_branch(val)">
                  Edit/ Update
                </a>
                <hr class="dropdown-divider">
                <a class="dropdown-item has-text-danger" @click="remove_branch(val)">
                  Remove
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="message-body has-text-left p-3 cursor_pointer" @click="redirect_to_branch(val)">
          <small class="is-size-6 is-size-7-mobile">{{val.detail}}</small>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Branch',
  props: {
    adminMode: Boolean,
    branchs: Array,
  },
  methods:{
    edit_branch(val){
      this.$emit("edit_branch",val);
    },
    redirect_to_branch(val){
      if(this.adminMode){
        this.$emit("manage_node",val)
      }else{
        this.redirect('/member/branch/'+val.id)
      }
    }
  }
}
</script>
